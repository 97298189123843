import React, { useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Typewriter from 'typewriter-effect';
import { IonIcon } from '@ionic/react';
import { send } from "ionicons/icons";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BridgeFromAptos from "../Events/Bridges/LayerZero/Aptos/From";
import { Container } from "react-bootstrap";
import { BaseText, Title } from "../../designSystem";
import sizes from "../../designSystem/sizes";
import JupiterSwap from "../Events/Chains/Solana/Protocols/Jupiter/Swap";
import JupiterPriceCheck from "../Events/Chains/Solana/Protocols/Jupiter/PriceCheck";
import GreaterEqual from "../Events/Core/Conditions/GreaterEqual";
import Multiply from "../Events/Core/Math/Multiply";
import RocketXSwap from "../Events/Bridges/RocketX/Bridge";
import PancakeSwap from "../Events/Chains/Bnb/Protocols/Pancake/Swap";
import Col from "react-bootstrap/Col";
import colors from "../../lib/designSystem/colors";
import Row from "react-bootstrap/Row";

const dropIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-50px);
        visibility: hidden;
    }
    1% {
        visibility: visible;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
`;




const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 86vh; // Ensures it at least covers the full viewport height
    padding: 10px;
    position: relative; // Required for absolute positioning of children
    
`;
//
const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #1df9c4;
    border-radius: 3px;
    padding: 5px;
    max-width: 650px;
    width: calc(100% ); // Full width taking into account padding
    left: 50%; // Center horizontally
    @media (max-width: 600px) { // Adjust for smaller screens
        padding: 5px;
    }
`;

const FakeInput = styled.div`
    flex-grow: 1;
    min-height: 30px;
    font-size: 18px;
    position: relative;
    background: transparent; // Invisible background
    color: #FFF; // Adjust text color to match the neon theme
    @media (max-width: 600px) { // Adjust for smaller screens
        min-height: 18px;
        font-size: 0.9rem; // Add horizontal margins on smaller screens
    }
`;

const ExampleTitle = styled(Title)`
  font-size: 48px;
  line-height: 50px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: ${sizes.md}px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Button = styled.button`
    background-color: rgba(22, 206, 185, 0.08);
    color: #1df9c4;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    padding: 8px 12px;
    margin-left: 10px;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex; // Ensure icons align properly
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) { // Adjust for smaller screens
        min-height: 18px;
        font-size: 0.9rem; // Add horizontal margins on smaller screens
    }
    
    &:hover {
        background-color: #0056b3;
    }

    &:active, &.active {
        background-color: #0041a3;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        transform: translateY(4px);
    }
`;

const ComponentContainer = styled.div`
    visibility: hidden;  // Components are initially hidden
    animation: ${dropIn} 0.5s ease forwards;
`;

const TypewriterSubtitle = styled(BaseText)`
  display: flex;
  font-size: 0.8rem;
  line-height: 24px;
  color: ${colors.text};
  text-align: center;
`;

const TypewriterSubtitleRow = styled(Row)`
  margin: 0;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
`;



const componentsMap = {
  "JupiterSwap": JupiterSwap,
  "JupiterPrice": JupiterPriceCheck,
  ">=": GreaterEqual,
  "Multiply": Multiply,
  "BridgeFromAptos": BridgeFromAptos,
  "RocketX": RocketXSwap,
  "PancakeSwap": PancakeSwap
};

// Component
const TypeWriterComponent = () => {
  const [components, setComponents] = useState([]);
  const buttonRef = useRef(null);
  const [renderKey, setRenderKey] = useState(0); // To force re-render

  const simulateClick = (scriptComponents) => {
    if (buttonRef.current && scriptComponents.length > 0) {
      // @ts-ignore
      buttonRef.current.classList.add('active');
      // @ts-ignore
      buttonRef.current.click();
      setTimeout(() => {
        // @ts-ignore
        buttonRef.current.classList.remove('active');
      }, 200);
    } else {
      setTimeout(() => {
      }, 5000);
    }
    setComponents(scriptComponents.map((comp, index) => ({ ...comp, delay: index * 500 })));
  };


  const renderComponents = () => {
    return components.map((comp, index) => (
      //@ts-ignore
      <ComponentContainer key={index} style={{ animationDelay: `${comp.delay}ms` }}>
        { //@ts-ignore
          React.createElement(componentsMap[comp.type], comp.props)}
        {index < components.length - 1 && (
          <div className="flex justify-center">
            <ArrowDownwardIcon className="text-white" />
          </div>
        )}
      </ComponentContainer>
    ));
  };


  return (
    <ParentContainer className={"max-w-[700px] md:max-w-[calc(100% - 260px)] mx-auto min-h-[100vh]"}>
      <Container>
        <div className="flex justify-center">
          <ExampleTitle>AUTOMATE CRYPTO</ExampleTitle>
        </div>
        <div className="flex justify-center mb-3">
          <TypewriterSubtitleRow>
            <Container>
              <TypewriterSubtitle>
                At Surge, we make earning in crypto effortless. Our AI-powered platform automates profitable tasks like sniping new tokens and farming airdrops. Manage multiple wallets across different blockchains and maximize your gains quickly and easily. Start making money with Surge today.
              </TypewriterSubtitle>
            </Container>
          </TypewriterSubtitleRow>
        </div>
        <div>
          <SearchContainer>
            <FakeInput>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.changeDelay(10)
                    .typeString("Swap 5 SOL for USDC on SOL when the price increases by 2x")
                    .callFunction(() => simulateClick([]))
                    .callFunction(() => simulateClick([
                      { type: "JupiterPrice", props: { arg1: "So11111111111111111111111111111111111111112" } },
                      { type: "JupiterPrice", props: { arg1: "So11111111111111111111111111111111111111112" } },
                      { type: "Multiply", props: { arg1: "[#1]", arg2: "2" } },
                      { type: ">=", props: { arg1: "[#2]", arg2: "[#3]", arg3: "2" } },
                      {
                        type: "JupiterSwap",
                        props: {
                          arg1: "5",
                          arg2: "So11111111111111111111111111111111111111112",
                          arg3: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
                        },
                      }]))
                    .pauseFor(5000)
                    .deleteAll(1)
                    .typeString("Swap 1 SOL for USDC on SOL then bridge to BNB and buy CAKE")
                    .callFunction(() => simulateClick([]))
                    .callFunction(() => simulateClick([
                      {
                        type: "JupiterSwap",
                        props: {
                          arg1: "1",
                          arg2: "So11111111111111111111111111111111111111112",
                          arg3: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
                        },
                      },
                      {
                        type: "RocketX",
                        props: {
                          arg1: "[#1]",
                          arg2: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
                          arg3: "SOL",
                          arg4: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
                          arg5: "BNB",
                        },
                      },
                      {
                        type: "PancakeSwap",
                        props: {
                          arg1: "[#2]",
                          arg2: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
                          arg3: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                        },
                      },
                    ]))
                    .pauseFor(5000)
                    .deleteAll(1)
                    .start();
                }}
                options={{
                  loop: true,
                  autoStart: true,
                  delay: 10,
                  deleteSpeed: 1,
                }}
              />
            </FakeInput>
            <Button ref={buttonRef} style={{ background: "rgba(22, 206, 185, 0.08)" }}>
              <IonIcon icon={send} style={{ fontSize: "20px", color: "#1df9c4" }} />
            </Button>
          </SearchContainer>
        </div>
          <div  style={{ position: "relative", top: "20px" }} className={"min-h-[50vh]"}>
            {renderComponents()}
          </div>

      </Container>
    </ParentContainer>
);
};

export default TypeWriterComponent;
