// rules-of-hooks is disabled for this file so that we avoid calling pointless
// useEffects on the framer canvas.
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { useIsOnFramerCanvas } from "./useIsOnFramerCanvas"
import { useOnCurrentTargetChange } from "../components/NavigationTargetContext"

type CallbackMap = Record<string, (() => void) | undefined>
function callbackForVariant(map: CallbackMap, variant: string) {
    if (map[variant]) return map[variant]
    if (variant in map) return undefined
    return map.default
}

/**
 * Executes a callback when the base variant changes. Events will not be
 * executed on the Framer canvas.
 *
 * @internal
 */
export function useOnVariantChange(variant: string, callbackMap: CallbackMap) {
    const isOnFramerCanvas = useIsOnFramerCanvas()
    if (isOnFramerCanvas) return

    const isInCurrentTargetRef = React.useRef(true)

    useOnCurrentTargetChange(
        isInTarget => {
            if (!isInCurrentTargetRef.current && isInTarget) {
                const callback = callbackForVariant(callbackMap, variant)
                if (callback) callback()
            }
            isInCurrentTargetRef.current = isInTarget
        },
        [callbackMap]
    )

    React.useEffect(() => {
        if (isInCurrentTargetRef.current) {
            const callback = callbackForVariant(callbackMap, variant)
            if (callback) callback()
        }
    }, [variant, callbackMap])
}
