import * as React from "react"
import { MotionConfig } from "framer-motion"
import { layoutTransition } from "./layoutTransition"
import "./autoValueHandlers"

interface Props {
    children: React.ReactNode
}

/** @public */
export function MotionSetup({ children }: Props) {
    return <MotionConfig features={[layoutTransition]}>{children}</MotionConfig>
}
