import * as React from 'react';
import Typography from '@mui/material/Typography';
import Events from "../../../../../index";
import {Grid, TextField} from "@mui/material";
import JupiterLogo from "../Logo";
import SolanaLogo from "../../../../../Chains/Solana/Logo";

//@ts-ignore
const MiddleSection = ({arg1, handleInput}) => {
    return (
        <div><Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
            <Typography variant="subtitle1" component="h2">
               Check price of
            </Typography>
             <TextField InputProps={{
                 inputProps: {
                     style: { textAlign: "center", padding:3},
                 }
             }}size="small" onChange={e => handleInput(0, e)} sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg1} variant="filled" color="success" focused />
            <Typography variant="subtitle1" component="h2">
                on Jupiter
            </Typography>
            </Grid>
        </div>
    );
};
//@ts-ignore
export default function JupiterPriceCheck({ arg1, onInputChange, index, deleteEvent, editEvent }) {
    const handleInput = (argName:any, e:any) => {
        onInputChange(argName, e.target.value);
    };
    return (
        <div>
            <Events index={index} editEvent={editEvent} deleteEvent={deleteEvent} icon1={<SolanaLogo />} icon2={<JupiterLogo />} middleSection={<MiddleSection arg1={arg1} handleInput = {handleInput}/>} />
        </div>
    );
}
