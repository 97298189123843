import * as React from 'react';
import rocketLogo from '../../../../assets/images/rocketx.png';

const RocketXLogo = () => (
    <div className={"mt-[10px] mb-[10px]"}>
    <img src={rocketLogo} alt="Core Logo" width="47.25" height="14.6" />
    </div>
);

export default RocketXLogo;
