import * as React from "react"
const JupiterLogo = (props:any) => (
    <div className={"pt-[3px]"}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={16}
        fill="none"
        viewBox="0 0 30 30"
        {...props}
    >
        <g clipPath="url(#a)" filter="url(#b)">
            <path
                fill="url(#c)"
                d="M3.09 25.167a16.433 16.433 0 0 0 11.621 6.75c-1.185-1.784-2.907-3.424-5.057-4.673-2.15-1.25-4.428-1.931-6.563-2.077Z"
            />
            <path
                fill="url(#d)"
                d="M12.543 22.27C8.4 19.864 3.916 19.25.708 20.334c.31 1.024.718 2.015 1.22 2.96 2.787-.065 5.83.692 8.663 2.337 2.832 1.645 4.998 3.915 6.323 6.369 1.07-.033 2.134-.17 3.177-.407-.648-3.323-3.406-6.915-7.548-9.323Z"
            />
            <path
                fill="url(#e)"
                d="M32.285 12.5A16.42 16.42 0 0 0 11.846.627c3.546.434 7.48 1.765 11.34 4.007 3.86 2.242 6.967 5.001 9.1 7.868Z"
            />
            <path
                fill="url(#f)"
                d="M27.127 20.358c-1.815-3.013-4.923-5.9-8.753-8.124-3.83-2.225-7.875-3.495-11.389-3.58-3.091-.072-5.411.826-6.363 2.466l-.02.028c-.085.308-.159.616-.226.925 1.33-.525 2.87-.817 4.584-.85 3.81-.071 8.073 1.147 12.008 3.433 3.935 2.286 7.108 5.388 8.932 8.732.818 1.506 1.329 2.99 1.53 4.407.236-.21.467-.428.691-.654l.016-.032c.952-1.641.584-4.101-1.01-6.75Z"
            />
            <path
                fill="url(#g)"
                d="M15.46 17.248C9.598 13.842 3.117 13.309 0 15.685c.006.745.063 1.488.17 2.224a12.904 12.904 0 0 1 2.817-.522c3.483-.262 7.322.709 10.806 2.734 3.484 2.025 6.23 4.88 7.728 8.033.414.864.73 1.771.941 2.706.694-.272 1.367-.59 2.017-.953.522-3.886-3.152-9.251-9.018-12.659Z"
            />
            <path
                fill="url(#h)"
                d="M30.143 15.314c-1.835-3.01-4.97-5.904-8.827-8.144-3.857-2.239-7.918-3.53-11.443-3.633-2.688-.078-4.77.574-5.848 1.804 4.48-.76 10.392.517 16.121 3.845 5.73 3.329 9.767 7.832 11.326 12.1.534-1.545.07-3.676-1.329-5.972Z"
            />
        </g>
        <defs>
            <linearGradient
                id="c"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <linearGradient
                id="d"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <linearGradient
                id="e"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <linearGradient
                id="f"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <linearGradient
                id="g"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <linearGradient
                id="h"
                x1={21.5}
                x2={6.667}
                y1={6.5}
                y2={32}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#C7F284" />
                <stop offset={1} stopColor="#00BEF0" />
            </linearGradient>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h32.285v32H0z" />
            </clipPath>
            <filter
                id="b"
                width={77.775}
                height={77.49}
                x={-22.745}
                y={-20.47}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={2.274} />
                <feGaussianBlur stdDeviation={11.372} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_11565_169621"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_11565_169621"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
    </div>
)
export default JupiterLogo
