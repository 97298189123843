import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Grid, TextField} from "@mui/material";
import Events from '../../..';
import RocketXLogo from "../../../../Bridges/RocketX/Logo";


// @ts-ignore
const MiddleSection = ({arg1, arg2, arg3, arg4, arg5,handleInput}) => {


    return (
        <div><Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Typography variant="subtitle1" component="h2">
                Bridge
            </Typography>
            <TextField InputProps={{
                inputProps: {
                    style: { textAlign: "center", padding:3},
                }
            }}size="small"  onChange={e => handleInput(0, e)} sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg1} variant="filled" color="success" focused />
            <TextField InputProps={{
                inputProps: {
                    style: { textAlign: "center", padding:3},
                }
            }}size="small"  onChange={e => handleInput(1, e)} sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg2} variant="filled" color="success" focused />
            <Typography variant="subtitle1" component="h2">
                on
            </Typography>
            <TextField InputProps={{
                inputProps: {
                    style: { textAlign: "center", padding:3},
                }
            }
            } onChange={e => handleInput(2, e)} size="small" sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg3} variant="filled" color="success" focused />
            <Typography variant="subtitle1" component="h2">
                for
            </Typography>
            <TextField InputProps={{
                inputProps: {
                    style: { textAlign: "center", padding:3},
                }
            }
            } onChange={e => handleInput(3, e)} size="small" sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg4} variant="filled" color="success" focused />
            <Typography variant="subtitle1" component="h2">
                on
            </Typography>
            <TextField InputProps={{
                inputProps: {
                    style: { textAlign: "center", padding:3},
                }
            }
            } onChange={e => handleInput(4, e)} size="small" sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg5} variant="filled" color="success" focused />

        </Grid>
        </div>
    );
};

// @ts-ignore
export default function RocketXSwap({ arg1, arg2, arg3, arg4, arg5, onInputChange, index, deleteEvent, editEvent }) {
    const handleInput = (argName:any, e:any) => {
        onInputChange(argName, e.target.value);
    };
    return (
        <div><Events index={index} editEvent={editEvent} deleteEvent={deleteEvent} icon1={<RocketXLogo />} icon2={undefined} middleSection={<MiddleSection arg1={arg1} arg2={arg2} arg3={arg3} arg4={arg4} arg5 = {arg5} handleInput = {handleInput}/>} />
        </div>
    );
}

