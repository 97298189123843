import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import classNames from "classnames";
import {IonIcon} from "@ionic/react";
import {pencilOutline, trashOutline} from "ionicons/icons";
import Typography from "@mui/material/Typography";

// @ts-ignore
export default function Event({ icon1, icon2, middleSection, index, editEvent, deleteEvent }) {

    const addEvent = (eventName:string) => {
        editEvent(eventName,index)
    }
    return (
        <div className={"w-12/12 pl-[5px] pr-[5px]"}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AppBar position="static">
                    <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={"mr-[5px]"}>
                                {icon1}
                            </div>
                            <div className={"mr-[10px]"}>
                                {icon2}
                            </div>
                        </div>
                        <div style={{ fontSize: ".875rem", lineHeight: "1.5" , marginTop: '5px', marginBottom: '5px'}}>                {middleSection}
                        </div>
                        <div className=" inline-flex">
                            <button
                              className={classNames(" mr-2 flex hover:text-blue-300")}
                            >
                                <IonIcon icon={pencilOutline} />
                            </button>
                            <button
                              className={classNames("  flex hover:text-red-300")}
                            >
                                <IonIcon icon={trashOutline} />
                            </button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
}
