import * as React from "react"
import { useConstant } from "../components/utils/useConstant"
import { useOnCurrentTargetChange } from "../components/NavigationTargetContext"

type RejectCallback = (reason: string) => void

function rejectPending(pendingTimers: Set<number>, pendingPromises: Set<RejectCallback>) {
    pendingTimers.forEach(t => clearTimeout(t))
    pendingTimers.clear()

    pendingPromises.forEach(reject => reject && reject("Callback cancelled by variant change"))
    pendingPromises.clear()
}

function createSet<T>() {
    return new Set<T>()
}

/**
 * Create callbacks that can be cancelled if the component is unmounted, the
 * active variant changes, or the component moves out of the target screen in a
 * Framer prototype.
 *
 * @internal
 */
export function useActiveVariantCallback(baseVariant: string | undefined) {
    const pendingPromises = useConstant<Set<RejectCallback>>(createSet)
    const pendingTimers = useConstant<Set<number>>(createSet)

    // If the component moves out of the current screen in a Framer prototype,
    // or the current screen is being unmounted via an AnimatePresence animation
    // cancel all pending events.
    useOnCurrentTargetChange(() => {
        return () => rejectPending(pendingTimers, pendingPromises)
    })

    // If the component is unmounted, cancel all pending events.
    React.useEffect(() => {
        return () => rejectPending(pendingTimers, pendingPromises)
    }, [])

    // If the base variant of the component changes, cancel all pending events.
    React.useEffect(() => {
        rejectPending(pendingTimers, pendingPromises)
    }, [baseVariant])

    return React.useRef({
        /**
         * Create a callback that can be cancelled if the base variant changes.
         */
        activeVariantCallback: (callback: (...args: any[]) => Promise<void>) => (...args: any[]) => {
            return new Promise((resolve, reject) => {
                pendingPromises.add(reject)
                return callback(...args).then(() => resolve(true))
            }).catch(() => {
                // Swallow errors caused by rejecting this promise.
            })
        },

        /**
         * Execute a callback after a defined period of time. The callback will not
         * be called if pending events are cancelled because the timeout will be
         * cancelled.
         */
        delay: async (callback: () => void, msDelay: number) => {
            await new Promise(resolve => pendingTimers.add(window.setTimeout(() => resolve(true), msDelay)))
            callback()
        },
    }).current
}
