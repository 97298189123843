import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { Title, BaseText } from "../../designSystem";
import sizes from "../../designSystem/sizes";
import colors from "../../lib/designSystem/colors";
import worldAnimationData from "../../assets/world.json";

const MainContainer = styled(Container)`
  padding: 0px 0;
  position: relative;
`;

const MissionTitleRow = styled(Row)`
  margin: 0;
  text-align: center;
  justify-content: center;
  width: 100%;
`;

const MissionSubtitleRow = styled(Row)`
  margin: 0;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
`;

const MissionTitle = styled(Title)`
  font-size: 48px;
  line-height: 56px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: ${sizes.md}px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Image = styled.img`
  width: 100%; // Ensures the image fills the container
  height: auto; // Maintains the aspect ratio of the image
`;

const MissionSubtitle = styled(BaseText)`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.text};
  text-align: center;
`;

const Images = styled.div`
  width: 20%;
  padding-top:40px;
  margin: 0 auto; // Centers the image container
`;

const ImageContainer = () => {
  const [isTabVisible, setTabVisible] = useState(true);
  const options = {
    animationData: worldAnimationData,
    loop: true,
    autoplay: isTabVisible,  // Control autoplay based on tab visibility
  };


  useEffect(() => {
    const handleVisibilityChange = () => {
      setTabVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return <Images><Image src="./world.png" alt="world"/></Images>;
};

const EvenTheOdds = () => {
  return (
    <MainContainer>
      <MissionTitleRow fluid>
        <Col xs={12} lg={10} xl={8}>
          <Container>
            <MissionTitle>Empowering Everyone with DeFi</MissionTitle>
          </Container>
        </Col>
      </MissionTitleRow>

      <MissionSubtitleRow>
        <Col md={12} xl={6}>
          <Container>
            <MissionSubtitle>
              Surge democratizes powerful blockchain tools, making them accessible to everyone, anywhere. Our platform offers affordable automation, premium templates, and events, enabling users to manage complex crypto and non-complex tasks effortlessly. With Surge, you can automate multi-chain operations, manage multiple wallets, and optimize your strategies easily. We're leveling the playing field, ensuring everyone has access to advanced capabilities previously reserved for elite developers.
            </MissionSubtitle>
          </Container>
        </Col>
        <Col xs={12}>
          <ImageContainer/>
        </Col>
      </MissionSubtitleRow>
    </MainContainer>
  );
};

export default EvenTheOdds;
