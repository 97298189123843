import * as React from "react"

/**
 * Render a React component with css included in a sibling <style> tag.
 * @beta
 */
export const withCSS = <T extends object>(Component: React.ComponentType<T>, escapedCSS: string) =>
    React.forwardRef((props: T, ref) => {
        return (
            <>
                <Component {...props} ref={ref} />
                <style
                    dangerouslySetInnerHTML={{
                        __html: escapedCSS,
                    }}
                />
            </>
        )
    })
