import * as React from "react"
import { Size } from "../../render/types/Size"
import { useForceUpdate } from "../../modules/useForceUpdate"
import { RenderTarget } from "../../render/types/RenderEnvironment"
import ResizeObserver from "resize-observer-polyfill"

const DEFAULT_SIZE = 200

type ObserverCallback = (size: Size) => void
class SharedObserver {
    #sharedResizeObserver
    #callbacks = new WeakMap<Element, ObserverCallback>()

    constructor() {
        this.#sharedResizeObserver = new ResizeObserver(this.updateResizedElements.bind(this))
    }

    private updateResizedElements(entries: ResizeObserverEntry[]) {
        for (const entry of entries) {
            const callbackForElement = this.#callbacks.get(entry.target)
            if (callbackForElement) callbackForElement(entry.contentRect)
        }
    }

    observeElementWithCallback(element: HTMLElement, callback: ObserverCallback) {
        this.#sharedResizeObserver.observe(element)
        this.#callbacks.set(element, callback)
    }

    unobserve(element: HTMLElement) {
        this.#sharedResizeObserver.unobserve(element)
        this.#callbacks.delete(element)
    }
}

const sharedResizeObserver = new SharedObserver()

/**
 * Uses a globally shared resize observer, and returns an updated
 * size object when the element's size changes. This is the recommended way to
 * use a Resize Observer: https://github.com/WICG/resize-observer/issues/59.
 */
function useMeasuredSize(ref: React.MutableRefObject<HTMLDivElement | null>) {
    const forceUpdate = useForceUpdate()
    const size = React.useRef<Size | null>(null)

    function updateSize(newSize: Size) {
        if (!size.current || newSize.height !== size.current.height || newSize.width !== size.current.width) {
            size.current = { width: newSize.width, height: newSize.height }
            forceUpdate()
        }
    }

    // On mount, immediately measure and set a size. This will defer paint until
    // no more updates are scheduled. Additionally add our element to the shared
    // ResizeObserver with a callback to perform when the element resizes.
    // Finally, remove the element from the observer when the component is unmounted.
    React.useLayoutEffect(() => {
        if (!ref.current) return
        const { offsetWidth, offsetHeight } = ref.current

        // Defer paint until initial size is added.
        updateSize({
            width: offsetWidth,
            height: offsetHeight,
        })

        // Resize observer will race to add the initial size, but since the size
        // is set above, it won't trigger a render on mount since it should
        // match the measured size. Future executions of the callback will
        // trigger renders if the size changes.
        sharedResizeObserver.observeElementWithCallback(ref.current, updateSize)

        return () => {
            if (!ref.current) return
            sharedResizeObserver.unobserve(ref.current)
        }
    }, [])

    return size.current
}

/**
 * @internal
 */
export const SIZE_COMPATIBILITY_WRAPPER_ATTRIBUTE = "data-framer-size-compatibility-wrapper"

interface OptionalSizeProps {
    width?: number | string
    height?: number | string
}

/**
 * A HoC to enhance code components that depend on being rendered with exact
 * width and height props with width and height props determined via a shared
 * ResizeObserver.
 *
 * @FIXME Do not depend on this HoC. The current plan is to turn it into a no-op
 * after a deprecation period. If we need to provide this functionality to
 * customers after we migrate to a modules-first ecosystem, then we can provide
 * a new copy of this HoC or the `useMeasuredSize` hook, and recommend use
 * without a module version, allowing everyone to share the same ResizeObserver
 * on a single canvas.
 *
 * @internal
 */
export const withMeasuredSize = <T extends OptionalSizeProps>(Component: React.ComponentType<T>) => (props: T) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const size = useMeasuredSize(ref)
    const dataProps = { [SIZE_COMPATIBILITY_WRAPPER_ATTRIBUTE]: true }

    // The initial render will be delayed until the measured size is available.
    // When this HOC is used in image exports on the Desktop (Legacy) app,
    // however, we'll render anyway. This is because the Desktop app uses
    // `renderToStaticMarkup` for exports, which will not run any layout
    // effects, so the container size will never become available. This issue
    // will be addressed and fully resolved in the transition away from
    // `renderToStaticMarkup` and towards an offscreen renderer, which will run
    // layout effects before it captures an image. At that point, the condition
    // below could just become `Boolean(size)`.
    const shouldRender = RenderTarget.current() === RenderTarget.export || Boolean(size)

    // In the export case, we'll sometimes be provided with explicit width and
    // height to use as fallback. This is a temporary measure and will only
    // happen when the used width / height is a fixed number. This code should
    // be removed once we're no longer using `renderToStaticMarkup` for export.
    const fallbackWidth = props.width ?? DEFAULT_SIZE
    const fallbackHeight = props.height ?? DEFAULT_SIZE

    return (
        <div style={{ width: "100%", height: "100%", pointerEvents: "none" }} ref={ref} {...dataProps}>
            {shouldRender && (
                <Component {...props} width={size?.width ?? fallbackWidth} height={size?.height ?? fallbackHeight} />
            )}
        </div>
    )
}
