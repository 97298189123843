import * as React from 'react';
import Typography from '@mui/material/Typography';
import Events from "../../../../../index";
import {Grid, TextField} from "@mui/material";
import BnbLogo from "../../../../../Chains/Bnb/Logo";
import PancakeLogo from "../Logo";


// @ts-ignore
const MiddleSection = ({arg1, arg2, arg3, handleInput}) => {


    return (
        <div><Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography variant="subtitle1" component="h2">
                    Swap
                </Typography>
                <TextField InputProps={{
                    inputProps: {
                        style: { textAlign: "center", padding:3},
                    }
                }}size="small"  onChange={e => handleInput(0, e)} sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'70px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg1} variant="filled" color="success" focused />
                <TextField InputProps={{
                    inputProps: {
                        style: { textAlign: "center", padding:3},
                    }
                }}size="small"  onChange={e => handleInput(1, e)} sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'90px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg2} variant="filled" color="success" focused />
                <Typography variant="subtitle1" component="h2">
                    for
                </Typography>
                <TextField InputProps={{
                    inputProps: {
                        style: { textAlign: "center", padding:3},
                    }
                }
                } onChange={e => handleInput(2, e)} size="small" sx = {{p: '20px 8px', display: 'flex', alignItems: 'center',height: '5px', width:'90px', alignContent: 'center', justifyContent:'center'}} defaultValue={arg3} variant="filled" color="success" focused />
                <Typography variant="subtitle1" component="h2">
                    on Pancake
                </Typography>
            </Grid>
        </div>
    );
};

// @ts-ignore
export default function PancakeSwap({ arg1, arg2, arg3, onInputChange, index, deleteEvent, editEvent }) {
    const handleInput = (argName:any, e:any) => {
        onInputChange(argName, e.target.value);
    };
    return (
        <div><Events index={index} editEvent={editEvent} deleteEvent={deleteEvent} icon1={<BnbLogo />} icon2={<PancakeLogo />} middleSection={<MiddleSection arg1={arg1} arg2={arg2} arg3={arg3} handleInput = {handleInput}/>} />
        </div>
    );
}

