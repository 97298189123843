import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import { Title, BaseText } from "../../designSystem";
import sizes from "../../designSystem/sizes";
import colors from "../../lib/designSystem/colors";
import TypeWriterComponent from "../Typewriter";

const MainContainer = styled(Container)`
  position: relative;
    padding-bottom: 80px;
`;

const MissionTitleRow = styled(Row)`
  margin: 0;
  text-align: center;
  justify-content: center;
  width: 100%;
`;

const MissionSubtitleRow = styled(Row)`
  margin: 0;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
`;

const MissionTitle = styled(Title)`
  font-size: 48px;
  line-height: 56px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: ${sizes.md}px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const MissionSubtitle = styled(BaseText)`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.text};
  text-align: center;
`;

const MissionFactor = styled(Row)`
  font-size: 16px;
  color: ${colors.text};
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(4px);
  border-radius: 6px;
  padding: 18px 0px;
  text-align: center;
  width: 100%;
  margin: 0;

  > div {
    padding: 0px 24px;

    @media (min-width: ${sizes.md}px) {
      &:not(:last-child) {
        border-right: 2px solid ${colors.background.three};
      }
    }
  }

  @media (max-width: ${sizes.md}px) {
    padding: 0;

    > div {
      padding: 18px 0;
      border-bottom: 1px solid ${colors.background.three};
    }
  }
`;

const FactorTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;

const FactorAmount = styled.div`
  font-family: VCR;
  color: ${colors.primaryText};
`;

const Frame = styled.div`
  display: none;

  @media (min-width: ${sizes.md}px) {
    position: absolute;
    display: flex;
    background: #333;
    top: 80px;
    height: calc(100% - 160px);
    width: 100%;
    filter: blur(30px);
  }
`;

const glow = keyframes`
  0% {
    border-left: 10px solid ${colors.green};
    border-right: 10px solid ${colors.green};
    box-shadow: 2px 4px 40px ${colors.green};
    background: ${colors.green}cc;
  }

  25% {
    background: black;
  }
`;

const Bar = styled.div<{ delay: number }>`
  width: calc(100% / 10);
  height: 100%;
  background: black;

  animation: 4s ${glow} infinite;
  animation-delay: ${(props) => props.delay}s;
`;

const Mission = () => {
  return (
    <MainContainer>
      <Frame>
        {[0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5].map((i) => (
          <Bar key={i} delay={i}></Bar>
        ))}
      </Frame>
      <MissionTitleRow fluid>
        <Col xs={12} lg={10} xl={8}>
          <Container>
            <MissionTitle>POWERED BY AI</MissionTitle>
          </Container>
        </Col>
      </MissionTitleRow>

      <MissionSubtitleRow>
        <Col md={12} xl={6}>
          <Container>
            <MissionSubtitle>
              Surge leverages AI, particularly a large language model (LLM) named Genesys, to transform user instructions into executable blockchain tasks. This application of AI in Surge is revolutionary in the crypto sector, offering a significant enhancement over traditional, manual processes.
            </MissionSubtitle>
          </Container>
        </Col>
      </MissionSubtitleRow>

      <MissionSubtitleRow>
        <Col xs={12} md={12} lg={8}>
          <Container>
            <MissionFactor>
              <Col xs={12} md={4}>
                <FactorTitle>Chains Integrated</FactorTitle>
                <FactorAmount>10+</FactorAmount>
              </Col>
              <Col xs={12} md={4}>
                <FactorTitle>Protocols integrated</FactorTitle>
                <FactorAmount>25+</FactorAmount>
              </Col>
              <Col xs={12} md={4}>
                <FactorTitle>Time saved</FactorTitle>
                <FactorAmount>Years</FactorAmount>
              </Col>
            </MissionFactor>
          </Container>
        </Col>
      </MissionSubtitleRow>
    </MainContainer>
  );
};

export default Mission;
