import { assertNever } from "./assert"
import { getServiceMap } from "./ServiceMap"

const LOCAL_MODULE_ENTITY_IDENTIFIER_REGEX = /^local-module:([a-zA-Z0-9]+\/[^:]+)(?::(.+))?$/
const EXTERNAL_MODULE_ENTITY_IDENTIFIER_REGEX = /^module:([a-zA-Z0-9]+)\/([a-zA-Z0-9]+)\/([^:]+)(?::(.+))?$/

export const enum ModuleType {
    Canvas = "canvasComponent",
    Code = "codeFile",
}

export type ModuleIdentifier = ExternalModuleIdentifier | LocalModuleIdentifier
export type ExternalModuleIdentifier = ExternalModuleBareIdentifier | ExternalModuleExportIdentifier
export type LocalModuleIdentifier = LocalModuleBareIdentifier | LocalModuleExportIdentifier

export interface ExternalModuleBareIdentifier {
    readonly kind: "externalModule"
    /**
     * The unparsed representation of the identifier.
     */
    readonly value: string
    readonly moduleId: GlobalModuleId
    readonly saveId: string
    readonly file: string
    readonly importSpecifier: string
}

export type ExternalModuleExportIdentifier = Omit<ExternalModuleBareIdentifier, "kind"> & {
    readonly kind: "externalModuleExport"
    readonly exportSpecifier: string
}

export interface LocalModuleBareIdentifier {
    readonly kind: "localModule"
    readonly value: string
    readonly localId: LocalModuleId
    readonly type: string
}

export type LocalModuleExportIdentifier = Omit<LocalModuleBareIdentifier, "kind"> & {
    readonly kind: "localModuleExport"
    readonly exportSpecifier: string
}

/**
 * Error definition identifiers don’t include the export specifier part,
 * because a module that failed to import doesn’t export anything.
 */
export function errorIdentifierFromModuleIdentifier(identifier: ModuleIdentifier): string {
    if (identifier.kind === "externalModuleExport") {
        return `module:${identifier.moduleId}/${identifier.saveId}/${identifier.file}`
    } else if (identifier.kind === "localModuleExport") {
        return `local-module:${identifier.localId}`
    } else {
        // This is already a bare module identifier.
        return identifier.value
    }
}

export function isExternalModuleIdentifier(
    value: ModuleIdentifier | string | undefined
): value is ExternalModuleIdentifier {
    if (typeof value === "string") value = parseModuleIdentifier(value)
    return value?.kind === "externalModule" || value?.kind === "externalModuleExport"
}

export function isLocalModuleIdentifier(value: ModuleIdentifier | string | undefined): value is LocalModuleIdentifier {
    if (typeof value === "string") value = parseModuleIdentifier(value)
    return value?.kind === "localModule" || value?.kind === "localModuleExport"
}

export function isModuleExportIdentifier(
    value: ModuleIdentifier | string | undefined
): value is LocalModuleExportIdentifier | ExternalModuleExportIdentifier {
    if (typeof value === "string") value = parseModuleIdentifier(value)
    return value?.kind === "externalModuleExport" || value?.kind === "localModuleExport"
}

export function isModuleIdentifier(value: ModuleIdentifier | string | undefined): value is ModuleIdentifier {
    if (typeof value === "string") value = parseModuleIdentifier(value)
    if (!value) return false
    return (
        value.kind === "externalModule" ||
        value.kind === "externalModuleExport" ||
        value.kind === "localModule" ||
        value.kind === "localModuleExport"
    )
}
export function externalModuleIdentifier(moduleId: string, saveId: string, file: string): ExternalModuleBareIdentifier
export function externalModuleIdentifier(
    moduleId: string,
    saveId: string,
    file: string,
    exportSpecifier: string
): ExternalModuleExportIdentifier
export function externalModuleIdentifier(
    moduleId: string,
    saveId: string,
    file: string,
    exportSpecifier?: string
): ExternalModuleIdentifier
export function externalModuleIdentifier(
    moduleId: string,
    saveId: string,
    file: string,
    exportSpecifier?: string
): ExternalModuleIdentifier {
    const path = `${moduleId}/${saveId}/${file}`
    const importSpecifier = `${getServiceMap().modulesCDN}/${path}`
    if (exportSpecifier) {
        return {
            kind: "externalModuleExport",
            value: `module:${path}:${exportSpecifier}`,
            moduleId: asGlobalId(moduleId),
            saveId,
            file,
            importSpecifier,
            exportSpecifier,
        }
    } else {
        return {
            kind: "externalModule",
            value: `module:${path}`,
            moduleId: asGlobalId(moduleId),
            saveId,
            file,
            importSpecifier,
        }
    }
}

export function localModuleIdentifier(localId: LocalModuleId): LocalModuleBareIdentifier
export function localModuleIdentifier(localId: LocalModuleId, exportSpecifier: string): LocalModuleExportIdentifier
export function localModuleIdentifier(localId: LocalModuleId, exportSpecifier?: string): LocalModuleIdentifier
export function localModuleIdentifier(localId: LocalModuleId, exportSpecifier?: string): LocalModuleIdentifier {
    // Type is always the first part of the local id.
    const [type] = localId.split("/")
    if (exportSpecifier) {
        return {
            kind: "localModuleExport",
            value: `local-module:${localId}:${exportSpecifier}`,
            localId,
            type,
            exportSpecifier,
        }
    } else {
        return {
            kind: "localModule",
            value: `local-module:${localId}`,
            localId,
            type,
        }
    }
}

export function localModuleIdForStableName(type: ModuleType, name: string): LocalModuleId {
    // If the name is stable and unique we can use it to generate a local module id.
    return asLocalId(`${type}/${name}`)
}

export function localModuleIdentifierForStableName(
    type: ModuleType,
    name: string,
    exportSpecifier: string
): LocalModuleIdentifier {
    const localId = localModuleIdForStableName(type, name)
    return localModuleIdentifier(localId, exportSpecifier)
}

export function parseModuleIdentifier(value: string | undefined): ModuleIdentifier | undefined {
    if (!value) return undefined

    const matchLocal = value.match(LOCAL_MODULE_ENTITY_IDENTIFIER_REGEX)
    if (matchLocal) {
        const localId = asLocalId(matchLocal[1])
        const exportSpecifier = matchLocal[2] as string | undefined
        return localModuleIdentifier(localId, exportSpecifier)
    }

    const externalMatch = value.match(EXTERNAL_MODULE_ENTITY_IDENTIFIER_REGEX)
    if (externalMatch) {
        // Note: the export specifier match may be undefined because it's optional.
        const [, moduleId, saveId, path, exportSpecifier] = externalMatch
        return externalModuleIdentifier(moduleId, saveId, path, exportSpecifier as string | undefined)
    }

    return undefined
}

export function withExportSpecifier(
    identifier: LocalModuleIdentifier | LocalModuleExportIdentifier,
    exportSpecifier: string
): LocalModuleExportIdentifier
export function withExportSpecifier(
    identifier: ExternalModuleIdentifier | ExternalModuleExportIdentifier,
    exportSpecifier: string
): ExternalModuleExportIdentifier
export function withExportSpecifier(
    identifier: ModuleIdentifier,
    exportSpecifier: string
): ExternalModuleExportIdentifier | LocalModuleExportIdentifier
export function withExportSpecifier(
    identifier: ModuleIdentifier,
    exportSpecifier: string
): ExternalModuleExportIdentifier | LocalModuleExportIdentifier {
    if (identifier.kind === "externalModule" || identifier.kind === "externalModuleExport") {
        return externalModuleIdentifier(identifier.moduleId, identifier.saveId, identifier.file, exportSpecifier)
    } else if (identifier.kind === "localModule" || identifier.kind === "localModuleExport") {
        return localModuleIdentifier(identifier.localId, exportSpecifier)
    } else {
        assertNever(identifier)
    }
}

/**
 * 20-character-long id containing alphanumeric (upper case and lower case) characters.
 */
export type GlobalModuleId = Opaque<string, "GlobalModuleId">
export function asGlobalId(id: string): GlobalModuleId {
    return id as GlobalModuleId
}

/**
 * ID that includes the module's type and a locally unique ID containing 7 alphanumeric characters or "_".
 * E.g. "codeFile/AbcD_23"
 */
export type LocalModuleId = Opaque<string, "LocalModuleId">
export function asLocalId(id: string): LocalModuleId {
    return id as LocalModuleId
}

type Opaque<T, LABEL extends string> = T & { readonly __OPAQUE__: LABEL }
