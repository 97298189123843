import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useScreenSize from "../../lib/hooks/useScreenSize";
import github from "../../img/Footer/github.svg";
import discord from "../../img/Footer/discord.svg";
import twitter from "../../img/Footer/twitter.svg";
import chevron from "../../img/Footer/chevron.svg";
import globe from "../../img/Footer/globe.svg";
import { URLS } from "../../lib/constants/constants";
import { AppLogo } from "../../lib/assets/icons/logo";
import {
  Chains,
  READABLE_CHAIN_NAMES,
  VaultOptions,
} from "../../lib/constants/constants";
import { Assets } from "../../lib/store/types";
import { getAssetLogo } from "../../lib/utils/asset";
import styled from "styled-components";
import { useEffect, useState } from "react";
import colors from "../../lib/designSystem/colors";
import sizes from "../../designSystem/sizes";
import { BaseLink } from "../../designSystem";
import { motion } from "framer-motion";

const StickyFooterContainer = styled.div`
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  display: flex;
  z-index: 100;

  > * {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;
//@ts-ignore
const Progress = styled(CircularProgressbar)`
  position: sticky;
  color: white;
  width: 32px;
  height: 32px;
  left: 0;
  text-align: center;

  > * {
    width: fit-content;
  }
`;

const ProgressContainer = styled.div<{ highlight: boolean }>`
  height: fit-content;
  width: fit-content;
  margin-bottom: 12px;
  border-radius: 50%;
  position: relative;

  ${(props) =>
    props.highlight &&
    `
  transition: 0.2s;
  box-shadow: 0px 0px 40px rgba(29,249,196, 0.64) !important;
`};
`;

const ProgressLogo = styled(motion.div)<{ dimensions: number; margin: number }>`
  position: absolute;
  transform: translateX(${(props) => props.margin}px)
    translateY(${(props) => props.margin}px);
  left: 0;
  top: 0;
  display: flex;

  > * {
    animation: 2s fadeInDown;
    height: ${(props) => props.dimensions}px;
    width: ${(props) => props.dimensions}px;
  }
`;

const Scroller = () => {
  const { height } = useScreenSize();
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setProgress(
        (window.scrollY / (document.body.clientHeight - height)) * 100
      );
    });
  }, [height]);

  return (
    <ProgressContainer highlight={progress > 99}  style={{ marginLeft: '12px' }} >
      <Progress
        value={progress}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0,

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "round",

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.25,

          // Colors
          pathColor: progress > 99 ? colors.green : `#ffffff`,
          trailColor: "#1C1C22AA",
          backgroundColor: "#00000000",
        })}
      />

      {progress > 99 && (
        <ProgressLogo
          dimensions={16}
          margin={8}
          transition={{
            duration: 0.5,
            type: "keyframes",
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <AppLogo />
        </ProgressLogo>
      )}
      {progress <= 99 && (
        <ProgressLogo
          dimensions={10}
          margin={11}
          transition={{
            duration: 0.5,
            type: "keyframes",
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <img alt="scroll-indicator" src={globe} />
        </ProgressLogo>
      )}
    </ProgressContainer>
  );
};

const Socials = styled.div`
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(40px);
  border-radius: 100px;
  margin-bottom: 12px;
  margin-right: 12px;

  @media (max-width: ${sizes.md}px) {
    display: none;
  }
`;

const SocialButton = styled.button`
  justify-content: center;
  cursor: pointer;
  margin: 0 $spacing-2;
  transition: 0.5s;
  background: none;
  border: none;
  padding: 20px;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    transition: 0.5s;

    > img {
      transition: 0.75s;
      filter: brightness(3);
    }
  }
`;

const SocialMedia = () => {
  return (
    <Socials>
      <BaseLink to={URLS.github} target="_blank" rel="noreferrer noopener">
        <SocialButton>
          <img src={github} alt="github" />
        </SocialButton>
      </BaseLink>
      <BaseLink to={URLS.discord} target="_blank" rel="noreferrer noopener">
        <SocialButton>
          <img src={discord} alt="discord" />
        </SocialButton>
      </BaseLink>
      <BaseLink to={URLS.twitter} target="_blank" rel="noreferrer noopener">
        <SocialButton>
          <img src={twitter} alt="twitter" />
        </SocialButton>
      </BaseLink>
    </Socials>
  );
};

const StickyFooter = () => {
  return (
    <StickyFooterContainer>
      <div>
        <Scroller />
      </div>
      <div>
        <SocialMedia />
      </div>
    </StickyFooterContainer>
  );
};

export default StickyFooter;
