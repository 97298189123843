import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Mission from "./components/Mission";
import Footer from "./components/Footer";
import Investors from "./components/Investors";
import PolicyPage from "./pages/PolicyPage";
import TermsPage from "./pages/TermsPage";
import colors from "./lib/designSystem/colors";
import StickyFooter from "./components/StickyFooter/StickyFooter";
import "./lib/i18n/config";
import TypeWriterComponent from "./components/Typewriter";
import { createTheme, ThemeProvider } from "@mui/material";
import EvenTheOdds from "./components/EvenTheOdds";

const Body = styled.div`
  background-color: ${colors.background.one};
`;

const MainContainer = styled.div`
  > * {
    margin-bottom: 80px;
  }
`;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
                  <Body>
                    <Router>
                      <Header />

                      <Switch>
                        <Route path="/" exact>
                          <MainContainer>
                            <Hero />
                            <TypeWriterComponent/>
                            <EvenTheOdds />
                            <Mission />
                            <Investors />
                          </MainContainer>
                        </Route>

                        <Route path="/policy">
                          <PolicyPage></PolicyPage>
                        </Route>

                        <Route path="/terms">
                          <TermsPage></TermsPage>
                        </Route>

                        <Route>
                          <Redirect to="/" />
                        </Route>
                      </Switch>

                      <Footer />
                    </Router>
                    <StickyFooter />
                  </Body>
    </ThemeProvider>
  );
}

export default App;
